import {
    ActionIcon,
  Button,
  Center,
  CopyButton,
  Divider,
  Paper,
  rem,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { useState } from "react";
import API from "../api/api";
import { notifications } from "@mantine/notifications";
import { useForm } from '@mantine/form';
import { IconCheck, IconCopy } from "@tabler/icons-react";

export default function GenerateGolink() {
  const [newURL, setNewURL] = useState("");
  const api = new API();

  const submitHandler = async (values)=>{
    setNewURL("");
    await api.generateGolink(values.URL).then((res)=>{
        setNewURL(res.data);
    }).catch(()=>{
        notifications.show({title:"Bir Hata Oluştu.", message:"Bizden kaynaklı bir hata oluştu. Lütfen daha sonra tekrar deneyin.", color:"red"})
    });
  }

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      URL: '',
    },

    validate: {
      URL: (value) => (URLValidate(value) ? null : 'Geçersiz URL'),
    },
  });

  const URLValidate = (url) => {
    const regex =
      /https:\/\/sosyalhalisaha\.com\/filtre\/(\d+)_(\d+)_(\d+)_(\d{4}-\d{1,2}-\d{1,2})_(\d{2}:\d{2})/;
    return regex.test(url);
  };

  return (
    <Center h={"100vh"} bg={"APKSGray.0"}>
      <Paper p={"xl"} style={{transition:"300ms all", maxHeight:newURL ? "350px": "250px", overflow:"clip"}} shadow="xl" w={400}>
      <form onSubmit={form.onSubmit((values) => submitHandler(values))}>
        <Stack>
          <TextInput
            label={"Maç linki"}
            placeholder="https://..."
            description={"Oynadığınız son maçın linki."}
            {...form.getInputProps('URL')}
          />
          <Button type="submit">Oluştur</Button>
          {newURL &&
          <>
          <Divider></Divider>
          <Text ta={"center"} c={"gray"} size="xs">Link oluşturuldu. Aşağıdan linki kopyalayıp kullanabilirsiniz.</Text>
          
          <TextInput readOnly value={newURL} rightSection={
            <CopyButton value={newURL} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? 'Kopyalandı' : 'Kopyala'} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'APKSORANGE'} variant="subtle" onClick={copy}>
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
          }/>
          </> }
        </Stack>
    </form>
      </Paper>
    </Center>
  );
}
