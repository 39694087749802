import { MantineProvider, createTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ModalsProvider } from "@mantine/modals";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  /** Put your mantine theme override here */
  primaryColor: "APKSBlue",
  colors: {
    APKSOrange: [
      "#fff1e2",
      "#ffe3cc",
      "#ffc69b",
      "#ffa664",
      "#fe8b37",
      "#fe7a1a",
      "#ff7109",
      "#e46000",
      "#cb5400",
      "#b14600",
    ],
    APKSBlue: [
      "#e4f5ff",
      "#cde6ff",
      "#9bccff",
      "#64afff",
      "#3997fe",
      "#1e87fe",
      "#0980ff",
      "#006de4",
      "#0061cd",
      "#0054b5",
    ],
    APKSGray: [
      "#f5f5f5",
      "#e7e7e7",
      "#cdcdcd",
      "#b2b2b2",
      "#9a9a9a",
      "#8b8b8b",
      "#848484",
      "#717171",
      "#656565",
      "#575757",
    ],
    APKSOverallColors: [
      "#cc0000", // dark red (weak)
      "#cc3300", // dark orange
      "#cc6600", // medium dark orange
      "#cc9900", // light dark orange
      "#cccc00", // pale dark orange (average)
      "#99cc00", // pale dark green
      "#66cc00", // light dark green
      "#33cc00", // medium dark green
      "#00cc00", // dark green
      "#009900", // dark green (powerful)
    ],
  },
});

root.render(
  <MantineProvider theme={theme}>
    <Notifications />
    <ModalsProvider>
      <App />
    </ModalsProvider>
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
